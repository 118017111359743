import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Work a weakenss for :20`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`Support You Local Box Workout 3`}</strong></p>
    <p>{`50-DB Deadlifts`}</p>
    <p>{`50-Situps`}</p>
    <p>{`50-Step Ups`}</p>
    <p>{`50-Single Arm DB Thrusters`}</p>
    <p>{`rx=50/35`}{`#`}{` db’s`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      